<template>
  <section class="Category">
    
    <div class="page-header mb-0">
      <h3 class="page-title pt-2">Add Tag</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0">
          <li class="breadcrumb-item">
            <router-link to="/catalog/tag" class="text-primary">Tag</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Add Tag</li>
        </ol>
      </nav>
    </div>
    <form class="pt-2" @submit.prevent="submitTag">
      <div class="row pt-2">
        <div class="col-lg-12">
          <div class="card mb-3">
            <div class="card-body">
              <div class="form-group mb-1">
                <label class="fs-12 col-form-label" for="name">Name</label>
                <input
                  type="text"
                  v-model="tag.name"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && !$v.tag.name.required }"
                />
                <div v-if="submitted && $v.tag.name.$error" class="invalid-feedback">
                  Name is required
                </div>
              </div>
              <div class="form-group">
                <label class="fs-12 col-form-label" for="sort">Sort</label>
                <input type="number" v-model="tag.sort" class="form-control" />
              </div>
              <div class="form-group">
                <label for="status">Status</label>
                <div>
                <toggle-button v-model="tag.active" :labels="{ checked: 'true', unchecked: 'false' }" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <button class="btn btn-primary float-right mt-3">Submit</button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { catalogApi } from "../../../api";

export default {
  name: "AddTag",
  data() {
    return {
      tag: { name: "", sort: 0, active: true },
      submitted: false,
      
    };
  },
  validations: {
    tag: { name: { required } },
  },
  methods: {
    async submitTag() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;

      

      const tagData = { ...this.tag, active: this.tag.active ? 1 : 0 };
      const response = await catalogApi.addTag(tagData);

      
      if (response.status === 200) {
        this.$swal({ toast: true, position: "top-end", showConfirmButton: false, text: response.messages, type: "success", timer: 3000 });
        this.$router.push("/catalog/tag");
      } else {
        this.$swal({ title: "Error", text: response.messages, type: "error" });
      }
    },
  },
};
</script>
